.main-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}


.profile-container {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-details p {
  margin: 0;
  margin-bottom: 20px;
}


.avatar {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  border: 4px solid rgb(255, 255, 255);
  border-radius: 50%;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 15px rgba(255, 0, 0, 1);
}


.profile-heading {
  text-align: center;
  margin-top: 40px;
}

.profile-heading p {
  margin: 5px 0;

}

.profile-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.profile-table td {
  padding: 5px;
  width: 50%;
}




@media (max-width: 768px) {
  .profile-details {
    flex-direction: column;
  }

  .profile-container {
    max-width: 100%;
    width: 80%;
    height: auto;
    margin: 0 20px;
  }

  .profile-table td {
    padding: 2px;
  }




}