.n-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.n-modal-content {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.n-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  /* margin: 2px 2px 0 0; */
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #8a6d4c;

}

.n-close-button:hover {
  background-color: red;
  color: wheat;
  border-top-right-radius: 12px;
}

.n-modal-content h2 {
  color: #8a6d4c;
  margin-bottom: 1rem;
}

.n-login-form {
  margin-top: 1rem;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.n-login-form-ip {
  margin-bottom: 20px;
  position: relative;
}

/* .n-login-form-ip input{
  width: 80%;
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 8px;
} */

/* label - placeholder for input fields */
.n-login-form-ip input {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
  color: #333;
}

.n-login-form-ip span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: rgb(0, 0, 0);
  /* text-transform: uppercase; */
  transition: 0.3s;
}

.n-login-form-ip input:focus{
  border: 1.5px solid rgba(0, 0, 0);
}

.n-login-form-ip input:valid+span,
.n-login-form-ip input:focus+span {
  color: rgb(0, 0, 0);
  transform: translateX(10px) translateY(-7px);
  font-size: 0.8em;
  padding: 0 10px;
  background: rgba(255, 255, 255);
  border-left: 1.5px solid rgb(0, 0, 0);
  border-right: 1.5px solid rgb(0, 0, 0);
  letter-spacing: 0.1em;
}



.n-forget-password {
  display: flex;
  justify-content: end;
  text-align: right;
  margin: 0 5px 20px 0;
  text-decoration: none;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}


.n-forget-password:hover {
  color: #0056b3;
  /* Adjust the hover color */
}


.n-or-divider-login {
  text-align: center;
  margin: 15px 0 15px 0;
  /* Increase margin above and below divider */
  color: #000;
  /* Adjust the text color */
  position: relative;

  width: calc(50% - 45px);
  /* Adjusted width */
}

.n-or-divider-login:before,
.n-or-divider-login:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background-color: #ccc;
  /* Adjust the color of the divider */
}

.n-or-divider-login:before {
  right: 60%;
  /* Position the left side of the divider */
  margin-right: 10px;
  /* Adjust spacing between the divider and text */
}

.n-or-divider-login:after {
  left: 60%;
  /* Position the right side of the divider */
  margin-left: 10px;
  /* Adjust spacing between the divider and text */
}