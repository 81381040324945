.disclaimer-container {
    background-color: #333;
    color: white;
    padding: 10px 0;
    text-align: center;
    height: 8px;
}


.disclaimer-content {
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

}

.disclaimer {
    font-size: 15px;
    color: rgb(255, 255, 255);
    animation: slideLeft 20s linear infinite;
    margin:2px 0;
}





@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }

    /* Start off-screen from the right */
    100% {
        transform: translateX(-100%);
    }

    /* Move off-screen to the left */
}

@media (max-width: 768px) {


    .disclaimer {
        animation: slideLeftMobile 12s linear infinite;
    }

    @keyframes slideLeftMobile {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(-450%);
        }
    }

}