/* General Page Style */
.subscription-page {
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
    padding: 40px 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
}

.title {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.description {
    font-size: 18px;
    color: #555;
    margin-bottom: 40px;
}

/* Plan Cards Container */
.plans {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
}

.plan-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 300px;
    text-align: center;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
}

.plan-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-card.selected {
    border: 2px solid #8a6d4c;
    background-color: #e8f5e9;
    /* Highlighted color */
}

.plan-header {
    margin-bottom: 20px;
}

.plan-title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
}

.plan-price {
    font-size: 24px;
    font-weight: bold;
    color: #8a6d4c;
    margin-top: 10px;
}

.plan-features {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: #777;
}

.plan-features li {
    margin-bottom: 10px;
}

.plan-action {
    margin-top: 20px;
}



/* Premium Plan Style
.premium {
    background-color: #f0f8ff;
    border: 2px solid #8a6d4c;
}

.premium .plan-title {
    color: #8a6d4c;
}

.premium .plan-price {
    color: #8a6d4c;
}
 */


/* "Recommended" label styling */
.recommended-label {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #ff4081;
    /* Pink color */
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

/* Selected Plan Summary */
.selected-plan-summary {
    margin-top: 40px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 18px;
    color: #333;
}




/* Strikethrough effect for original price */
.original-price {
    text-decoration: line-through;
    color: #888;
    /* Light gray color for the old price */
    margin-right: 10px;
    /* Spacing between original and discounted price */
    font-size:18px ;
}


.tnc-container {
    margin-top: 20px;
    font-size: 1em;
    color: #555;
  }
  
  .tnc-container input[type="checkbox"] {
    margin-right: 8px;
  }
  
  .tnc-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .tnc-container a:hover {
    text-decoration: underline;
  }
  



/* Mobile Styles */
@media (max-width: 768px) {
    .title {
        font-size: 28px;
    }

    .description {
        font-size: 16px;
    }

    .plans {
        gap: 20px;
    }

    .plan-card {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        /* Ensure padding does not affect width */
    }

    .plan-title {
        font-size: 24px;
    }

    .plan-price {
        font-size: 20px;
    }


    .recommended-label {
        top: 10px;
        right: 10px;
        font-size: 12px;
        padding: 6px 12px;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 24px;
    }

    .description {
        font-size: 14px;
    }

    .plan-title {
        font-size: 22px;
    }

    .plan-price {
        font-size: 18px;
    }


    .recommended-label {
        font-size: 12px;
        padding: 6px 12px;
    }
}