/* Department Page */
.n-department-page {
    font-family: 'Montserrat', sans-serif;
    padding: 2rem;
}

/* Hero Section */
.n-department-hero {
    background-color: #8a6d4c;
    color: white;
    padding: 3rem 2rem;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
}

.n-hero-content h1 {
    font-size: 3rem;
    font-family: 'Great Vibes', cursive;
    font-weight: bold;
    margin-bottom: 1rem;
}

.n-hero-content p {
    font-size: 1.5rem;
    line-height: 1.6;
    margin: 0;
}

/* Department Content */
.n-department-content {
    /* margin-top: 2rem; */
    text-align: center;
    max-width: 1200px;
    margin: auto;
}

.n-department-content h2 {
    font-size: 2.5rem;
    color: #8a6d4c;
    margin-bottom: 2rem;
}

.n-department-sems {
    display: flex;
    flex-wrap: wrap; 
    gap: 1.5rem; 
    cursor: pointer;
  }
  
  .n-department-sem {
    flex: 1 1 calc(33.333% - 1.5rem);
    box-sizing: border-box;
  }
.n-department-sem {
    background-color: #f5f5f5;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.n-department-sem h3 {
    font-size: 2rem;
    color: #8a6d4c;
    margin-bottom: 1rem;
}

.n-department-sem ul {
    list-style-type: none;
    padding: 0;
}

.n-department-sem li {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #333;
}

/* Media Queries */
@media (max-width: 768px) {
    .n-hero-content h1 {
        font-size: 2rem;
    }

    .n-hero-content p {
        font-size: 1.2rem;
    }

    .n-department-sem {
        padding: 1.5rem;
        width: 90%;
    }

    .n-department-sem h3 {
        font-size: 1.8rem;
    }
}
