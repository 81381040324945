.preloader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .preloader-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .preloader-typed-text {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    opacity: 0; /* Start with zero opacity */
    animation: fadeIn 0.5s ease-in forwards; /* Fade-in animation */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0; /* Start with zero opacity */
    }
    to {
      opacity: 1; /* End with full opacity */
    }
  }