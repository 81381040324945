/* .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  } */


.googleCalender {
  width: 400px;
  height: 400px;
}

.timeTable img{
  width: 400px;
  height: 400px;
}


/* About Us Styling */
#about-us,
#resources,
#contact {
  padding: 0 20px;
}

#about-us h2,
#resources h2,
#contact h2 {
  margin: 10px 0;
  color: #333;
}

#resources ul {
  list-style-type: none;
  padding: 0;
}

#resources li {
  margin-bottom: 5px;
}








/* Responsible Persons Section */
.responsible-persons {
  margin-top: 10px;
  border-top: 2px solid #929292;
  /* Added top border for separation */

}

.responsible-list {
  list-style-type: none;
  padding: 0 0 0 7%;
  margin: 0;
}



.responsible-list li {
  margin: 8px auto;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid #b6b6b6;
}



.view-all-btn {
  padding: 10px;
  color: #007bff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-all-btn:hover {
  background-color: #d4d4d4;
}






















@media only screen and (min-width: 435px) {
  /* For bigger like laptop */

  .responsible-list {
    width: 20%;
    padding: 0 0 0 2%;
  }



}











.subjects h2 {
  font-size: 1.8rem;
  color: var(--primary-brown);
  margin-bottom: 1rem;
  margin-left: 20px;
}

.subject-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.subject-card {
  background-color: white;
   color: #8a6d4c;
  padding: 1.5rem;
  border-radius: 12px;
  width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;


  h3{
    margin: 1px;
  }

  p{
    margin: 1px;
    font-size: 1rem;
  }

}


.subject-card:hover {
  transform: scale(1.05);
  /* Slight scale-up on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  /* Enhance shadow */
}


.subject-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.books-icon{
  font-size: 50px;
}


@media only screen and (max-width: 435px) {
  /* For smaller devices like mobile phones */


  .googleCalender {
    width: 100%;
    height: 400px;
  }

.timeTable img{
  width: 100%;
  height: 400px;
}

  #about-us h2,
  #resources h2,
  #contact h2 {
    font-size: 20px;
  }


  #about-us p,
  #contact p {
    font-size: 16px;
  }
  .responsible-list li {
    margin: 10px auto;

  }
  



  .subjects h2 {
    font-size: 1.5rem;
    margin-left: 10px;
  }



  .subject-cards {
    display: grid;
     grid-template-columns: repeat(4, 1fr);
     grid-gap: 8px;
     justify-content: flex-start;
  }

  .subject-card {
    padding: 12px;
    width: 50px;
    display: flex;
    color: #694c29;

    h3{
      margin: 1px;
      font-size: 1rem;
    }
  
    p{
      margin: 1px;
      font-size: 12px;
    }
  }

  .books-icon{
    font-size: 30px;
  }
  
  

}