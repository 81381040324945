.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    width: 90%;
    max-width: 500px;
    text-align: center;
  }
  
  .popup-content h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
  }
  
  .popup-content form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  
  .popup-content button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background: #333;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background: #676767;
  }
  
 
  