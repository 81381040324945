/* NavBar.css */

.navbar {
  background-color: #f4f4f4;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.home-btn {
  display: none;
}

.navbar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

}



.nav-item:last-child {
  margin-right: 0;
}

.nav-link {
  color: #333;
  font-weight: bold;
  padding: 10px;
  margin: 0 10px;
  border-radius: 5px;
  transition: color 0.3s, background-color 0.3s;
  font-size: 20px;
}

.nav-link:hover {
  color: white;
  background-color: rgba(37, 37, 37, 0.7);

}

.active {
  /* background-color: #4CAF50; Change background color to indicate active page */
  color: white;
  /* Change text color */
  background: linear-gradient(135deg, #4CAF50, #2196F3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}



.avatar-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 70px;
  right: 15px;
  background-color: #333;
  padding: 10px;
  margin-right: 45px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 116px;
}

.dropdown-menu button {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  margin: 5px 0;
  display: block;
  font-size: 18px;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.dropdown-menu button:hover {
  background-color: #95c3e5;
  /* Light blue hover color */

}




/* Media query for mobile screens */
/* @media (max-width: 768px) {

  .home-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    color: #fff;
    text-decoration: none;
    padding: 5px;
    border-radius: 5px;
    font-size: 30px;
  }

  .home-btn p {
    font-size: 25px;
  }

  .navbar-nav> :not(:first-child) {
    display: none;
  }

  .navbar {
    width: 100%;
    padding: 5px 10px;



  }

} */


/* Media query for mobile screens */
@media (max-width: 768px) {


  .navbar {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 50px;
    color: white;
    background-color: #333;
  }

  .navbar-burger {
    height: 100%;
    font-size: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;

  }

  .navbar-title {
    font-size: x-large;
    font-weight: bold;
  }

  .dropdown-menu {
    position: absolute;
    border: 1px solid #f4f4f4;
    top: 45px;
    right: 15px;
    margin-top: 0px;
    margin-right: 10px;

  }

  .active {
    border: 0.5px solid white;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin: 5px;
    font-weight: bold;
  }

  .avatar-container {
    border: 1px solid white;
    border-radius: 50%;
  }


}

.PiStudentBold,
.MdDashboard {
  margin-right: 5px;
  font-size: 1.6rem;
}


@media (max-width: 1100px) {
  .nav-link {
    font-size: 18px;
  }
  .PiStudentBold,
  .MdDashboard {
   
    font-size: 1.3rem;
  }
  


}