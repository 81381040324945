/* error page styles */

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.err-content {
  max-width: 70rem;
  text-align: center;
}


.err-content h2 {
  font-size: 18vw;
  line-height: 1em;
  /* background: -webkit-repeating-linear-gradient(-60deg, #0099ff, rgb(40, 156, 223), #b98acc, #ee8176, #b98acc, blue, #9b59b6); */
 
  background: -webkit-repeating-linear-gradient(-60deg, #007bff,  #469bf6, #ffffff, #ffffff,  #007bff, #ffffff, #0056b3, #ff0000);

  -webkit-text-stroke: 3px rgb(0, 0, 0);
  -webkit-background-clip: text;
  background-size: 500%;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Adjust text shadow for contrast */
  position: relative;
  animation: animate 5s ease-in-out infinite;
  margin: auto;
}








@keyframes animate {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 100% 0;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.err-content h4 {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 2rem;
}

.content .btns {
  margin: 25px 0;
  display: inline-flex;
  justify-content: center;
}

.content .btns a {
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid var(--btn-color);
  color: var(--btn-color);
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.content .btns a:hover {
  background: var(--btn-color);
  color: #fff;
}
