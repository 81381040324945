/* SignUp.css */

.signup-model {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(to bottom, #292829, #529fbc);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.signup-h2 {
  color: white;
  font-size: 2em;
  margin-bottom: 10px;
  margin-top: 0;
}


.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.9);
}

.form-group {

  position: relative;
  display: flex;
  flex-direction: row;

}

.inputbox {
  position: relative;
  margin-right: 10px;
}


.inputbox input,
.inputbox select,
.inputbox textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
  color: #333;
  transition: box-shadow 0.3s ease;
  margin-bottom: 20px;
}

.inputbox span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: rgb(0, 0, 0);
  transition: 0.3s;
}

.inputbox input:valid~span,
.inputbox input:focus~span,
.inputbox select:valid+span,
.inputbox select:focus+span,
.inputbox textarea:valid~span,
.inputbox textarea:focus~span {
  color: rgb(0, 0, 0);
  transform: translateX(10px) translateY(-7px);
  font-size: 0.8em;
  padding: 0 8px;
  background: rgba(255, 255, 255);
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0);
  letter-spacing: 0.1em;
}



/* Adjust the styles for different screen sizes */
@media only screen and (min-width: 768px) {

  /* For tablets and larger devices */
  .inputbox {
    width: 250px;
    /* Adjust as needed */
  }
}

@media only screen and (max-width: 767px) {

  /* For smaller devices like mobile phones */
  .inputbox {
    width: 100%;
    /* Use full width */
  }

  .signup-h2 {
    font-size: 1.5em;
  }

  .form-container h1 {
    font-size: 27px;
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: 435px) {

  /* For smaller devices like mobile phones */
  .inputbox span {

    font-size: 0.9em;

  }

}