.n-dss-page {
    margin: 0;

}

.n-hero-section {
    height: 100vh;
    background-image: url('../../Assets/bg1.jpg');
    background-size: cover;
    background-position: center;
    /* border: 2px solid red; */
    box-sizing: border-box;

}

.n-hero-content-h1 {
    position: absolute;
    /* Position relative to .hero-section */
    top: 42%;
    /* Vertical center */
    left: 44%;
    /* Horizontal center */
    transform: translate(-50%, -50%);
    /* Centering effect */
    text-align: center;
    /* Center text horizontally */
    max-width: 800px;
    /* Optional: Limit width for readability */
    z-index: 1;

    h1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        font-size: 6rem;
        text-align: center;
        margin: 0;
        font-family: 'Great Vibes', cursive;
        font-weight: bold;
    }

    p {
        font-size: 7rem;
    }


}

.n-hero-content-p {
    position: absolute;
    /* Position relative to .hero-section */
    bottom: 4%;
    /* Vertical center */
    left: 40%;
    /* Horizontal center */
    transform: translate(-50%, -50%);
    /* Centering effect */
    /* text-align: center; Center text horizontally */
    max-width: 600px;
    /* Optional: Limit width for readability */

    p {
        font-size: 1.8rem;
        line-height: 1.5;
    }
}




/* .features {
    height: 90vh;
    background-image: url('../assets/bg5.jpg');
    background-size: cover;
    background-position: center;
} */


.n-features {
    padding: 4rem 2rem;
    /* Added padding for better spacing */
    text-align: center;
    background-color: #f9f9f9;
    /* Light grey background for contrast */
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    /* Enhanced shadow for depth */
    margin: 2px 0 16px 0;
    /*Adds space above and below the section */
}

.n-features h2 {
    font-size: 2.5rem;
    /* Larger heading */
    color: #8a6d4c;
    /* Caramel brown color */
    margin-bottom: 2rem;
    /* Spacing below the heading */
}

.n-features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    /* Increased gap between cards */
}

.n-features-card {
    background-color: #f5f5f5;
    /* Light beige card background */
    border: 1px solid #e0e0e0;
    /* Subtle border for definition */
    border-radius: 12px;
    /* Rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    /* Deeper shadow for a 3D effect */
    padding: 2rem;
    /* Padding for content */
    width: 250px;
    /* Slightly larger cards */
    transition: transform 0.3s, box-shadow 0.3s;
    /* Smooth transition */
}

.n-features-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    /* Elevated shadow on hover */
}

.n-feature-icon {
    font-size: 3rem;
    /* Larger icons */
    margin-bottom: 1rem;
    /* Spacing below icon */
    color: #8a6d4c;
    /* Match icon color with theme */
}

.n-features-card h2 {
    font-size: 1.5rem;
    /* Card title size */
    color: #8a6d4c;
    /* Title color */
    margin: 0.5rem 0;
    /* Margin around title */
}

.n-features-card p {
    font-size: 1.1rem;
    /* Paragraph size */
    line-height: 1.6;
    /* Improved readability */
    color: #333;
    /* Darker text for contrast */
}


.n-about-section {
    padding: 4rem 2rem;
    text-align: center;
    height: 100vh;
    background-image: url('../../Assets/bg5.jpg');
    /* border: 2px solid red; */
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
}


.n-about-section h2 {
    font-size: 2.5rem;
    color: #8a6d4c;
    margin-bottom: 1rem;
}

.n-about-content {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}

.n-about-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 250px;
}

.n-contact-section {
    padding: 4rem 2rem;
    text-align: center;
}

.n-contact-section h2 {
    font-size: 2.5rem;
    color: #8a6d4c;
    margin-bottom: 1rem;
}

.n-contact-info {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}

.n-contact-method {
    background-color: #fff;
    border-radius: 12px;
    padding: 1rem;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.n-contact-method {
    a {
        text-decoration: none;
        color: black;
        font-size: 1.2rem;
    }
}

.n-contact-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.n-contact-form input,
.n-contact-form textarea {
    width: 80%;
    padding: 1rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.n-contact-form button {
    margin-top: 1rem;
}





/* Navbar styling */
.n-navbar {
    display: flex;
    gap: 50px;
    align-items: center;
    position: sticky;
    top: 0;
    background: linear-gradient(to right, rgba(226, 185, 141, 0.7) 0%, rgba(226, 185, 141, 0) 70%);
    padding: 10px 20px;
    color: rgb(0, 0, 0);
}


.n-navbar-links {
    display: flex;
    gap: 44px;
    align-items: center;
}

.n-nav-links {
    display: flex;
    gap: 20px;
}

.n-auth-links {
    display: flex;
    gap: 15px;
    align-items: center;
}

.n-navbar-links a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 1rem;
    font-family: 'Cinzel', serif;
    /* font-family: 'Montserrat', sans-serif; */
}

.n-navbar-links a:hover {
    text-decoration: underline;
}


/* General styling for the button */
.n-custom-button {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    /* Rounded corners */
    padding: 12px 24px;
    /* Padding around the text */
    cursor: pointer;
    outline: none;
    text-decoration: none;
    /* Remove underline from links inside buttons */
    display: inline-block;
    /* Ensures the button fits around its content */
    transition: all 0.3s ease;
    /* Smooth transition for hover effects */
    border: none;
    /* Remove default border */
    letter-spacing: 1px;
}

/* Styling for the filled button */
.n-custom-button.filled {
    color: #fff;
    /* White text color */
    background: linear-gradient(145deg, #c4a56e, #8a6d4c);
    /* Caramel brown gradient */
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2), -3px -3px 5px rgba(255, 255, 255, 0.2);
    /* Shadow effect for a 3D look */
    letter-spacing: 1.5px;
}

.n-custom-button.filled:hover,
.n-custom-button.filled:focus {
    background: linear-gradient(145deg, #8a6d4c, #c4a56e);
    /* Inverse gradient on hover/focus */
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.3), inset -1px -1px 3px rgba(255, 255, 255, 0.2);
    /* Inset shadow effect */
}

/* Styling for the bordered button */
.n-custom-button.bordered {
    color: #8a6d4c;
    /* Caramel brown text color */
    background: transparent;
    /* Transparent background */
    border: 2px solid #8a6d4c;
    /* Caramel brown border */
    box-shadow: none;
    /* No shadow for bordered button */
}

.n-custom-button.bordered:hover,
.n-custom-button.bordered:focus {
    color: #c4a56e;
    /* Light caramel color on hover */
    border-color: #c4a56e;
    /* Light caramel border on hover */
    background: rgba(255, 255, 255, 0.1);
    /* Subtle background color on hover */
}

/* Disable state for both button styles */
.n-custom-button:disabled {
    background: #b09e6f;
    /* Lighter caramel color for disabled state */
    border-color: #b09e6f;
    /* Border color for disabled state */
    cursor: not-allowed;
    box-shadow: none;
}



/* Dropdown styling improvements */
.n-streams-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    
}


.n-dropdown-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ddd;
    top: 50px; 
    left: 20;
    z-index: 100;
    width: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px; /* Rounded corners */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.n-dropdown-item {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    border-radius: 8px;
}

.n-dropdown-item:hover {
    background-color: #f5f5f5;
    color: #8a6d4c;
}

.n-dropdown-item svg {
    margin-left: 8px;
}

/* Show dropdown when open */
.n-streams-link.open + .n-dropdown-menu {
    display: flex;
    opacity: 1;
    visibility: visible;
}

/* Optional: Add animation for dropdown appearance */
@keyframes dropdownFadeIn {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Apply animation to the dropdown */
.n-dropdown-menu {
    animation: dropdownFadeIn 0.3s ease-out;
}

/* Close dropdown on hover out */
.n-streams-link:focus-within + .n-dropdown-menu {
    opacity: 1;
    visibility: visible;
}

/* Adjust for mobile responsiveness */
@media (max-width: 768px) {
    .n-dropdown-menu {
        top: 36px; 

    }
}



















/* Media Queries */
@media (max-width: 768px) {

    .n-hero-section {
        height: 93vh;
        background-image: url('../../Assets/bg1shrink.jpg');
        background-size: cover;
        background-position: center;
        /* border: 2px solid red;
    box-sizing: border-box; */

    }

    .n-hero-content-h1 {
        position: absolute;
        /* Position relative to .hero-section */
        top: 39%;
        /* Vertical center */
        left: 47%;
        /* Horizontal center */

        h1 {
            display: block;

            font-size: 3rem;
            /* Responsive title size */
        }

        p {
            margin: 0;
            font-size: 4rem;
        }
    }

    .n-hero-content-p {

        position: absolute;
        bottom: 16%;
        left: 34%;
        transform: rotate(32deg);
        /* Adjust the angle as needed */
        transform-origin: left;

        p {

            font-size: 1.1rem;
            /* Responsive paragraph size */

        }
    }

    .n-features-container {
        flex-direction: column;
        /* Stack cards vertically */
        align-items: center;
        /* Center cards */
        width: 100%;
    }

    .n-features-card {
        width: 80%;
        /* Make cards take full width on mobile */
        margin-bottom: 1rem;
        /* Add spacing between cards */
    }

    .n-about-content {
        flex-direction: column;
        /* Stack cards vertically */
        align-items: center;
        /* Center cards */
    }

    .n-about-card {
        width: 90%;
        /* Make cards take full width on mobile */
        margin-bottom: 1rem;
        /* Add spacing between cards */
    }

    .n-contact-info {
        flex-direction: column;
        /* Stack contact methods vertically */
        align-items: center;
        /* Center methods */
    }

    .n-contact-method {
        width: 90%;
        /* Make contact methods take full width on mobile */
        margin-bottom: 1rem;
        /* Add spacing between methods */
    }

    .n-navbar {
        flex-direction: row;
        /* Stack navbar items vertically */
        align-items: center;
        /* Center navbar items */
        gap: 10px;
        /* Add gap between items */
        background: linear-gradient(to right, rgba(226, 185, 141, 0.7) 0%, rgba(226, 185, 141, 0) 115%);
position: relative;
    }

    .n-navbar-links {
        flex-direction: column;
        /* Stack links vertically */
        align-items: center;
        /* Center links */
        gap: 5px;
    }

    .n-navbar-links a {
        font-size: 1rem;
        /* Smaller font size for mobile */
    }

    .n-custom-button {

        padding: 6px 12px;
    }

}