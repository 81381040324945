.student-list-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.title {
    text-align: center;
}
66262541

.total-students {
    font-size: 16px;
}

.student-table {
    width: 100%;
    border-collapse: collapse;
}

.student-table th, .student-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.student-table th {
    background-color: #f2f2f2;
}

.student-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.student-table tr:hover {
    background-color: #aaddff;
}
