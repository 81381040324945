/* SubjectPage.css */
.SubjectPage {
   /* border:10px solid red; */
   padding: 0;

}

.subjectContainer {
   max-width: 1200px;
   margin: 0 auto;
   margin-bottom: 5px;
   padding: 10px 10px;
   background-color: #f4f4f4;
   border-radius: 5px;
   box-shadow: 0 2px 4px
}

.search-container {
   padding: 15px 0;
   text-align: center;
}

#searchInput[type=text] {
   width: 50%;
   padding: 10px;
   margin: 0 auto;
   display: block;
}

.subject-title {
   font-size: 28px;
   font-weight: bold;
   margin-bottom: 20px;
   margin-left: 10px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.subSection {
   border: 1px solid #f5f5f5;
   margin-bottom: 10px;
   padding: 0 10px;
   border-radius: 10px;
   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}



.sectionTitle {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.edOptions {
   font-size: 20px;
}


.fa-edit :hover {
   color: #4CAF50;
   cursor: pointer;
}

.fa-trash :hover {
   color: red;
   cursor: pointer;
}


.pdfTitle {
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: space-evenly;
}


.pdf-container {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-gap: 20px;
   /* justify-content: center; */
}

.pdf-item {
   text-align: center;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 8px;
   background-color: #fff;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   margin-bottom: 10px;
   display: flex;
   flex-direction: column;
   height: 230px;
   transition: all 0.3s ease;
   cursor: pointer;

}

.pdf-item:hover {
   transform: scale(1.05);
   /* Slight scale-up on hover */
   box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
   /* Enhance shadow */
}



.pdf-viewer {
   position: relative;
   flex-grow: 1;
   overflow: hidden;
   color: #8a6d4c;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   transition: background-color 0.3s ease, transform 0.3s ease;
   font-size: 160px;
}


.pdf-viewer:focus {
   outline: none;
}





.pdf-item-desc {

   font-size: 16px;
}


/* reference section */

.refSection {
   margin: auto;
}


.refTable {
   width: 100%;
   border-collapse: collapse;
   table-layout: fixed;
   /* Prevent the columns from growing larger than their set width */
}

.refTable td {
   padding: 8px;
   text-align: left;
   /* border: 2px solid red; */
}


.refTable td.titleColumn {
   width: auto;
   /* Will automatically adjust to the widest title */
}

.refTable td.linkColumn {
   width: 50%;
   /* Fixed width for the link column */
   max-width: 100%;
   overflow: hidden;
   /* Hide any overflowing content */
   text-overflow: ellipsis;
   /* Add ellipsis for overflowed text */
   white-space: nowrap;
   /* Prevent wrapping of the link text */
   padding-left: 10px;
   /* Optional: adds padding to the left of the link */
}

.refTable td.actionsColumn {
   width: 80px;
}


.bulletPoint {
   font-size: 15px;
   color: #000;
   padding-left: 5px;
   padding-right: 8px;
}

.titleColumn {
   font-weight: bold;
   display: flex;
   flex-direction: row;
   align-items: center;
}

.linkColumn a {
   color: #007bff;
   text-decoration: none;
}

.linkColumn a:hover {
   text-decoration: underline;
}

.actionsColumn {
   display: flex;
   justify-content: center;
   /* Centers icons horizontally */
   align-items: center;
}

.fa-edit,
.fa-trash {
   margin: 0 5px;
   cursor: pointer;
}

.refTable tr:hover {
   background-color: #6cbdffab;
}

.addReferenceButton {
   width: 50%;
}


.add-section {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   background-color: #e7e7e7;
   /* Blue color */
   color: rgb(0, 0, 0);
   padding: 10px 20px;
   height: 250px;
   margin: 10px;
   border-radius: 5px;
   cursor: pointer;
   transition: background-color 0.3s ease;
}

.add-section:hover {
   background-color: #000000b9;
   color: #e7e7e7;

}

.plus-icon {
   font-size: 40px;
}

.add-section-text {
   font-size: 16px;
}




/* Media query for mobile size (less than 768px) */
@media (max-width: 768px) {

   .subjectContainer {
      padding: 5px;
      /* Adjusted for mobile */
   }



   .subject-title {
      font-size: 18px;
      margin-bottom: 15px;
   }

   #searchInput[type=text] {
      width: 80%;
      /* Adjusted for mobile */
      max-width: 100%;
      /* Adjusted for mobile */
   }


   .sectionTitle {
      font-size: 16px;
   }

   .edOptions {
      font-size: 16px;
   }


   .pdf-item {
      margin-left: 10px;
      margin-right: 10px;
      height: 200px;
   }



   .pdf-container {
      display: flex;
      flex-direction: row;
      /* Align items in a row */
      overflow-x: auto;
      /* Enable horizontal scrolling */
      gap: 0px;
      /* Space between items */
      padding: 20px 0;
      /* Padding for the container */
      scroll-snap-type: x mandatory;
      /* Snap to each item when scrolling */
   }

   .pdf-item {
      min-width: 150px;
      /* Set a minimum width for each item */
      max-width: 250px;
      /* Optional: max width if needed */
      text-align: center;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      height: auto;
      /* Allow height to adjust */
      flex-shrink: 0;
      /* Prevent items from shrinking */
      scroll-snap-align: start;
      /* Ensure items snap into place */
   }

   .pdf-item-desc {
      font-size: 16px;
      margin-top: 10px;
   }

   /* Customize the scrollbar */
   .pdf-container::-webkit-scrollbar {
      width: 12px;
      /* Width of the scrollbar */
      height: 8px;
      /* Height of horizontal scrollbar */
   }

   .pdf-container::-webkit-scrollbar-track {
      background: #f0f0f0;
      /* Track color */
      border-radius: 10px;
      /* Rounded corners for track */
   }

   .pdf-container::-webkit-scrollbar-thumb {
      background: #8a6d4c;
      /* Thumb color */
      border-radius: 10px;
      /* Rounded corners for thumb */
      border: 3px solid #f0f0f0;
      /* Border around the thumb for contrast */
   }

   .pdf-container::-webkit-scrollbar-thumb:hover {
      background: #8a6d4c;
      /* Darker thumb color on hover */
   }

   /* Optional: For Firefox and other browsers */
   .pdf-container {
      scrollbar-width: thin;
      /* Thin scrollbar */
      scrollbar-color: #8a6d4c #f0f0f0;
      /* Thumb color and track color */
   }

   /* Optional: Smooth scroll for better user experience */
   .pdf-container {
      scroll-behavior: smooth;
   }


   .pdf-viewer {
      font-size: 100px;
      padding: 12px;
   }

   .pdfTitle {
     font-size: 15px;
   }
   


   .add-pdf {
      width: 100px;
      flex-shrink: 0;
      scroll-snap-align: start;
   }


   /* reference section */

   .refTable td {
      display: block;
      width: 100%;
      box-sizing: border-box;
   }


   .refTable td.titleColumn,
   .refTable td.linkColumn {
      display: block;
      width: auto;
   }

   .refTable td.linkColumn {
      padding-left: 25px;
   }



   .add-section {
     height: 150px;
   }
}