body,html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  
}

.main-container {
  flex: 1; 
  overflow-y: auto; 
  align-items: center;
  justify-content: center;
}

/* Anchor tag in the entire site for hyperlink*/
a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}



/* Toastify css code */

.Toastify__toast-body {
  font-size: 1.2em;
}


.Toastify__close-button {
  display: none;
}